import {Button, HStack, VStack, Icon} from "@chakra-ui/react";
import {AiFillGithub, AiFillLinkedin} from "react-icons/ai";
import {IoGlobeOutline} from "react-icons/io5";
import * as React from "react";
import {BodyText, SmallHeading} from "./text";
import Card from "./card";
import {InlineLink} from "./inlineLink";

export default function BioCard() {
    return (
        <Card>
            <VStack spacing="20px" align="left" width="100%">
                <SmallHeading>
                    Created by Ben Robinson
                </SmallHeading>
                <BodyText textAlign="left">
                    I'm Ben Robinson - a student learning software development for Apple's platforms, the web and the server. Check out my other projects at <InlineLink href="https://benrobinson.dev">my site.</InlineLink>
                </BodyText>
                <HStack>
                    <Button
                        as="a"
                        href="https://benrobinson.dev/"
                        padding={4}
                        backgroundColor="siteBlue"
                    >
                        <Icon
                            as={IoGlobeOutline}
                            height="24px"
                            width="24px"
                            color="white"
                        />
                    </Button>
                    <Button
                        as="a"
                        href="https://github.com/benrobinson16"
                        padding={4}
                        backgroundColor="siteBlue"
                    >
                        <Icon
                            as={AiFillGithub}
                            height="24px"
                            width="24px"
                            color="white"
                        />
                    </Button>
                    <Button
                        as="a"
                        href="https://www.linkedin.com/in/benrobinson16"
                        padding={4}
                        backgroundColor="siteBlue"
                    >
                        <Icon
                            as={AiFillLinkedin}
                            height="24px"
                            width="24px"
                            color="white"
                        />
                    </Button>
                </HStack>
            </VStack>
        </Card>
    );
}