import Card from "./card";
import {Image} from "@chakra-ui/react";
import * as React from "react";

export default function ImageCard(props) {
    return (
        <Card
            height={{base: "60vh", md: "45vh"}}
        >
            <Image
                src={props.image}
                alt=""
                objectFit='contain'
                maxH="100%"
            />
        </Card>
    )
}