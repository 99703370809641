import * as React from "react"
import {VStack, Stack, Show, Hide} from "@chakra-ui/react";
import SiteHelmet from "../components/siteHelmet";
import ThemeProvider from "../components/themeProvider";
import {ReadableWrap} from "../components/wrap";
import TitleCard from "../components/titleCard";
import ImageCard from "../components/imageCard";
import {FitnessTextCard, IndoorsTextCard, IntervalTextCard, WarmupTextCard} from "../components/textCards";
import BioCard from "../components/bioCard";
import FooterText from "../components/footerText";

import WalkImage from "../images/walk-time.png";
import ConfirmationImage from "../images/confirmation.png";
import SummaryImage from "../images/summary.png";
import HomeImage from "../images/home.png";

export default function Index() {
    // const twoColumnsHidden = useBreakpointValue({ base: "none", md: "flex" });
    // const oneColumnHidden = useBreakpointValue({ base: "flex", md: "none" });

    return (
        <>
            <SiteHelmet title="Run/walk" description="An interval trainer for Apple Watch"/>
            <ThemeProvider>
                <ReadableWrap>
                    <VStack spacing="40px" width="100%" marginTop="12vh" marginBottom="40px">
                        <TitleCard />
                        <Stack spacing="40px" direction={{base: "column", md: "row"}}>
                            <Show above="md">
                                    <VStack spacing="40px" width="100%" id="vstackone">
                                        <ImageCard image={WalkImage} id="walkimageone" />
                                        <FitnessTextCard id="fitnesstextone" />
                                        <ImageCard image={ConfirmationImage} id="confirmationimageone" />
                                        <WarmupTextCard id="warmuptextone" />
                                    </VStack>
                                    <VStack spacing="40px" width="100%" id="vstacktwo">
                                        <IntervalTextCard id="intervaltextone" />
                                        <ImageCard image={SummaryImage} id="summaryimageone" />
                                        <IndoorsTextCard id="indoorstextone" />
                                        <ImageCard image={HomeImage} id="homeimageone" />
                                    </VStack>
                            </Show>
                            <Hide above="md">
                                <VStack spacing="40px" width="100%">
                                    <IntervalTextCard id="intervaltexttwo" />
                                    <ImageCard image={WalkImage} id="walkimagetwo" />
                                    <WarmupTextCard id="warmuptexttwo" />
                                    <ImageCard image={SummaryImage} id="summaryimagetwo" />
                                    <FitnessTextCard id="fitnesstexttwo" />
                                    <ImageCard image={ConfirmationImage} id="confirmationimagetwo" />
                                    <IndoorsTextCard id="indoorstexttwo" />
                                    <ImageCard image={HomeImage} id="homeimagetwo" />
                                </VStack>
                            </Hide>
                        </Stack>

                        <BioCard />
                        <FooterText />
                    </VStack>
                </ReadableWrap>
            </ThemeProvider>
        </>
    );
}