import Card from "./card";
import * as React from "react";
import {MediumHeading} from "./text";
import {chakra} from "@chakra-ui/react";

const height = {base: "30vh", md: "45vh"};

export function FitnessTextCard() {
    return (
        <Card
            height={height}
        >
            <MediumHeading>
                Flawlessly integrates with the <chakra.span color="siteGreen">Fitness</chakra.span> app
            </MediumHeading>
        </Card>
    );
}

export function IntervalTextCard() {
    return (
        <Card
            height={height}
        >
            <MediumHeading>
                Set intervals by <chakra.span color="siteGreen">time</chakra.span> or <chakra.span color="siteBlue">distance</chakra.span>
            </MediumHeading>
        </Card>
    );
}

export function IndoorsTextCard() {
    return (
        <Card
            height={height}
        >
            <MediumHeading>
                Run <chakra.span color="siteGreen">indoors</chakra.span> or <chakra.span color="siteBlue">outdoors</chakra.span>
            </MediumHeading>
        </Card>
    );
}

export function WarmupTextCard() {
    return (
        <Card
            height={height}
        >
            <MediumHeading>
                Track your <chakra.span color="siteGreen">warm-up</chakra.span> and <chakra.span color="siteBlue">cool-down</chakra.span>
            </MediumHeading>
        </Card>
    );
}