import Card from "./card";
import {Center, Stack, Image, VStack} from "@chakra-ui/react";
import RunImage from "../images/run.png";
import * as React from "react";
import {BigHeading, Subheading} from "./text";

export default function TitleCard() {
    return (
        <Card height={{base: null, md: "70vh"}}>
            <Stack direction={{base: "column", md: "row"}} spacing={{base: "40px", md: "60px"}} width="100%" height="100%" align="center">
                <Center height="100%" width="100%">
                    <VStack spacing="40px" width="100%">
                        <BigHeading>
                            Run/walk
                        </BigHeading>
                        <Subheading>
                            An interval trainer for Apple Watch
                        </Subheading>
                    </VStack>
                </Center>
                <Center width="100%" height="100%">
                    <Image
                        src={RunImage}
                        alt=""
                        maxHeight={{base: "50vh", md: "100%"}}
                        objectFit="contain"
                    />
                </Center>
            </Stack>
        </Card>
    );
}